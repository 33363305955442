<template>
  <v-container>
    <template>
      <v-data-table :headers="headers" :items="orders" class="elevation-1">
        <template v-slot:top></template>
        <template v-slot:item.edit="{ item }" class="align-center">
          <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
        </template>
        <template v-slot:item.status="{ item }" class="align-center">
          {{ item.status.text }}
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_ORDER_LIST_COMBO,
} from "@/store/orders.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  data: () => ({
    headers: [
      {
        text: "Tên khóa học",
        align: "start",
        sortable: false,
        value: "combo_title",
      },
      {text: "Người mua", value: "full_name"},
      {text: "Email", value: "email"},
      {text: "Số điện thoại", value: "phone_number"},
      {text: "Ngày mua", value: "register_date"},
      {text: "Trạng thái", value: "status"},
      {text: "Edit", value: "edit"},
    ],
    orders_status: [
      {
        text: "Đang xử lý",
        value: 1,
      },
      {
        text: "Đã thanh toán",
        value: 2,
      },
      {
        text: "Thất bại",
        value: 3,
      },
    ],
    editedIndex: -1,
    editedItem: {},
  }),
  computed: {
    ...mapGetters({
      orders: "orderList",
    }),
  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [{title: "Quản lý đơn hàng", titledad: 'Quản lý đơn hàng'}]);
    await this.$store.dispatch(GET_ORDER_LIST_COMBO, {});
    await this.formatOrderData();
  },
  watch: {
    orders: {
      immediate: true,
      deep: true,
      handler() {
      },
    },
  },
  methods: {
    editItem(item) {
      this.$router.push({
        path: `/order/detail-combo/${item.id}`
      })
    },
    formatOrderData() {
      this.orders.forEach((element) => {
        let obj = {};
        if (element.status === 1) {
          obj = Object.assign({}, this.orders_status[0]);
        } else if (element.status === 2) {
          obj = Object.assign({}, this.orders_status[1]);
        } else if (element.status === 3) {
          obj = Object.assign({}, this.orders_status[2]);
        }
        element.status = Object.assign({}, obj);
      });
    },
  },
};
</script>

<style scoped>
.btn-submit {
  background-color: black;
  color: white;
  border-radius: 10px;
}

.btn-cancel {
  border-radius: 10px;
}

.title {
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 29px;
  display: flex;
}

.schel {
  font-size: 40px;
}

</style>